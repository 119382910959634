window.BaseFunc=function() {
	var self = this;
	this.parseUrl = function(urlValue) {
		urlValue = urlValue + "";
		var urlQuery, urlParams;

		//处理url整体
		var urlReg = /^([^?#]*)\??([^#]*)(#?.*)$/;
		var urlMatch = urlValue.match(urlReg);
		urlQuery = "undefined" === typeof urlMatch[2] ? "" : urlMatch[2];

		//处理params
		urlParams = {};
		if ("" !== urlQuery) {
			var arrayParams = urlQuery.split('&');
			for (var i = 0; i < arrayParams.length; i++) {
				var paramStr = arrayParams[i];
				//忽略空的参数
				if ("" === paramStr) {
					continue;
				}

				var paramKey, paramValue;
				var placeEqual = paramStr.indexOf('=');

				if (-1 === placeEqual) {
					paramKey = paramStr;
					paramValue = "";
				} else {
					paramKey = paramStr.substr(0, placeEqual);
					paramValue = paramStr.substr(placeEqual + 1);
				}

				//忽略key为空的参数
				if ("" === paramKey) {
					continue;
				}

				urlParams[paramKey] = paramValue;
			}
		}

		return urlParams;
	};

	this.loadScripts = function(array, callback) {
		
		var loader = function(src, handler) {
			var script = document.createElement("script");
			script.src = src;
			script.onload = script.onreadystatechange = function() {
				script.onreadystatechange = script.onload = null;
				handler();
			};
			var head = document.getElementsByTagName("head")[0];
			(head || document.body).appendChild(script);

		
		};
		(function() {
			if (array.length != 0) {
				loader(array.shift(), arguments.callee);
			} else {
				if (callback != undefined) {
					callback();
				}

			}
		})();
	};
	//ajax post请求
	this.ddpost = function(http, para, fun) {
		$.ajax({
			url: http,
			data: para,
			cache: false,
			type: 'post',
			dataType: 'json',
			async: self.sync,
			error: function(obj, error) {
				console.error('error');

			},
			success: function(result) {
				fun(result);
			}
		});
	};
	//get请求
	this.ddget = function(http, para, fun) {
		$.ajax({
			url: http,
			data: para,
			cache: false,
			type: 'get',
			dataType: 'json',
			async: self.sync,
			error: function(obj, error) {
				console.error('error');
				//alert('error!');
			},
			success: function(result) {
				fun(result);
			}
		});

	};
	/**
	 * 打开自适应的iframe
	 * @param url 网址
	 * @param title 标题
	 * @param maxmin 是否可调节大小
	 * */
	this.openiframe = function(url, title, maxmin, successfun, endfun, wsize, hsize, rtop) {
		maxmin = maxmin || true;
		wsize = wsize || 0.83;
		hsize = hsize || 0.55;
		rtop = rtop || 0.25;
		//layer model
		width = document.body.clientWidth * wsize;
		height = document.documentElement.clientHeight * hsize;
		var nleft = document.documentElement.clientWidth * 0.1;
		var ntop = document.documentElement.clientHeight * rtop;
		var layifame = layer.open({
			type: 2,
			title: title,
			maxmin: true,
			area: [width + 'px', height + 'px'],
			offset: [ntop + 'px', nleft + 'px'],
			content: url,
			success: function(layero, index) {
				//layero.find('.layui-layer-min').remove();
				window.onresize = function() {

					var width = document.documentElement.clientWidth;
					var height = document.documentElement.clientHeight;
					var nwidth = width * wsize;
					var nheight = height * hsize;
					var lefts = width * 0.1;
					var tops = height * rtop;
					layer.style(index, {

						width: nwidth + 'px',
						height: nheight + 'px',
						margin: '0 auto',
						top: tops + 'px',
						left: lefts + 'px',

					});


					$('#layui-layer-iframe' + layifame).height(nheight);

				};
				if (successfun != undefined) {
					successfun(layifame);
				}

			},
			resizing: function(layero) {
				var nheights = $('#layui-layer' + layifame).height();

				$('#layui-layer-iframe' + layifame).height(nheights);

			},
			end: function() {
				if (endfun != undefined) {
					endfun(layifame);
				}
			},
			full: function(layero) {
				var nheights = (layero).height();
				$('#layui-layer-iframe' + layifame).height(nheights);
			},
			restore: function(layero) {
				var rtop = document.documentElement.clientHeight * rtop;
				var rwidth = document.body.clientWidth * wsize;
				var rheight = document.documentElement.clientHeight * hsize;
				var rleft = document.documentElement.clientWidth * 0.1;

				layer.style(layifame, {

					width: rwidth + 'px',
					height: rheight + 'px',
					margin: '0 auto',
					top: rtop + 'px',
					left: rleft + 'px',

				});
				var nheights = $(layero).height();
				$('#layui-layer-iframe' + layifame).height(nheights);
			}
		});

	};
	this.getmdpass = function(content) {//秘钥加密
		// var salt = CryptoJS.lib.WordArray.random(128 / 8);
		// var key256Bits500Iterations = CryptoJS.PBKDF2("Secret Passphrase", salt, {
		// 	keySize: 256 / 32,
		// 	iterations: 500
		// });
		// var iv = CryptoJS.enc.Hex.parse(seckey);
		// return CryptoJS.AES.encrypt(pass, key256Bits500Iterations, {
		// 	iv: iv
		// });
		var passphrase="Secret Passphrase";
		var salt = CryptoJS.lib.WordArray.random(256);
		var iv = CryptoJS.lib.WordArray.random(16);
		//for more random entropy can use : https://github.com/wwwtyro/cryptico/blob/master/random.js instead CryptoJS random() or another js PRNG	
		var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });
	
		var encrypted = CryptoJS.AES.encrypt(content, key, {iv: iv});
	
		return encrypted;
	};
		//获取html
	this.ddhtml = function(http, para, fun) {
	jQuery(document).ready(function($) {
		$.ajax({
			url: http,
			data: para,
			cache: false,
			type: 'get',
			dataType: 'html',
			async: self.sync,
			error: function(obj, error) {
				console.error('error');
				//alert('error!');
			},
			success: function(result) {
				fun(result);
			}
		});
});
	};
				//读取cookie
	this.getCookie = function(name) {
		var arr;
		var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
		
		if (document.cookie.match(reg)) {
			arr = document.cookie.match(reg);
			return unescape(arr[2]);
		} else {
			return null;
		}
	};
		this.jqvalidforms = function(form_id, url, befun,validfun,aftfun,scroll) {
		scroll=scroll||false;
		
		$(form_id).validationEngine({
			ajaxFormValidation: true,
			ajaxFormValidationURL: url,
			ajaxFormValidationMethod: 'post',
			promptPosition: 'bottomLeft',
			scroll:scroll,
			onBeforeAjaxFormValidation: function(form, options) {
				if(befun){
					befun();
				}
				
			},
			onValidationComplete: function(form, status) {

					if (!status) {
						if(validfun){
							validfun();
						}						
					}
				},
			onAjaxFormComplete: function(status, form, respon, options) {				
				aftfun(respon);	
				self.updatecsrf();			
			}
		});
	};
			//提交表单后更新csrf值
	this.updatecsrf = function() {
		var token = self.getCookie('securityToken');
		
		if (token) {
			$('input[name="securityToken"]').val(token);
		}
	};
			//读取cookie
	this.getCookie = function(name) {
		var arr;
		var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
		
		if (document.cookie.match(reg)) {
			arr = document.cookie.match(reg);
			return unescape(arr[2]);
		} else {
			return null;
		}
	};

};
