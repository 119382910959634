// JavaScript Document
/**
 * @author Perfectus Web Designs
 * @author website www.elegantdesignhub.com
 * @copyright Copyright 2013-2014 Perfectus Web Designs
 * JS Document
 */
//Scroll to top Script
var jq = jQuery.noConflict();
jq(function() {
	jq.fn.scrollToTop = function() {
		jq(this).hide().removeAttr("href");
		if (jq(window).scrollTop() != "0") {
			jq(this).fadeIn("slow");
		}
		var a = jq(this);
		jq(window).scroll(function() {
			if (jq(window).scrollTop() > "350") {
				jq(a).fadeIn("slow");
			} else {
				jq(a).fadeOut("slow");
			}
		});
		jq(this).click(function() {
			jq("html, body").animate({
				scrollTop: 0
			}, "slow");
		});
	};
});
jq(function() {
	jq("#w2b-StoTop").scrollToTop();
});
var acc = jQuery.noConflict();
acc(document).ready(function() {
	acc(".accordian-content").hide();
	acc(".accordian-header:first").addClass("active").next().show();
	acc(".accordian-header").click(function() {
		if (acc(this).next().is(":hidden")) {
			acc(".accordian-header").removeClass("active").next().slideUp();
			acc(this).toggleClass("active").next().slideDown()
		}
		return false;
	});
});

var img = jQuery.noConflict();
img(document).ready(function() {
	img(".group1").colorbox({
		rel: "group1"
	});
	img("#click").click(function() {
		img("#click").css({
			"background-color": "#f00",
			color: "#fff",
			cursor: "inherit"
		}).text("Open this window again and this message will still be here.");
		return false;
	});
});


var sticky = jQuery.noConflict();
sticky(document).ready(function() {
	var scroll_pos = 0;
	sticky(document).scroll(function() {
		scroll_pos = sticky(this).scrollTop();

		if (sticky(window).width() < 768) {
			sticky(".sticky-header-wrapper").css('display', 'none');
		} else if (scroll_pos > 250 && sticky(window).width() > 767) {
			sticky(".sticky-header-wrapper").fadeIn();
		} else {
			sticky(".sticky-header-wrapper").fadeOut();
		}
	});
});



var filternoconflict = jQuery.noConflict();
filternoconflict(document).ready(function() {
	filternoconflict('html').niceScroll({
		zindex: 9999,
		touchbehavior: false,
		cursorcolor: "#404040",
		cursoropacitymax: 0.6,
		cursorwidth: 10,
		cursorborder: 0,
		cursorborderradius: 0,
		background: '#f8f8f8'
	});
});



jQuery(document).ready(function($) {
	$("#cssmenu").menumaker({
		title: "MAIN MENU",
		format: "multitoggle"
		});

	layer.config({
		path: '/includes/templates/edify/jscript/'
	});

	$('body').on('click', '#loginbtn,#loginregist', function() {
		var loginurl = $(this).attr('datesrc');

		loginframe(loginurl);
	});

	$('body').on('click', '#loginregists', function() {
		var loginurl = $('#loginbtn').attr('datesrc');

		loginframe(loginurl);
	});



	$('.arrow-down').click(function() {
		$('.extrabox').slideToggle('slow');
		$('.arrow-down').addClass('opened');
		$('#homeask-img').attr('src', '/captchaimg/?type=homeask-img&rand=' + Math.random());
		return false;
	});
	$('body').on('click', '#homeask-img', function() {
		$('#homeask-img').attr('src', '');
		$('#homeask-img').attr('src', '/captchaimg/?type=homeask-img&rand=' + Math.random());
	});
	// $('body').on('click','#sendMessage',function(){
	// 	var sendername=$('#senderName').val();
	// 	var sendemail=$('#senderEmail').val();
	// 	var captcha=$('#homeask-captcha').val();
	// 	if(!sendername||!sendemail||!captcha){
	// 		layer.alert('some require fields are empty');
	// 		return false;
	// 	}
	// 	$.ajax({
	// 		type:'POST',
	// 		datatype:'json',
	// 	});
	// });



	// Ajax Contact
	if ($("#contactForm")[0]) {
		$('#contactForm').submit(function() {
			$('#contactForm .error').remove();
			$('#contactForm .requiredField').removeClass('fielderror');
			$('#contactForm .requiredField').addClass('fieldtrue');
			$('#contactForm span strong').remove();
			var hasError = false;
			var labelText = '';
			$('#contactForm .requiredField').each(function() {
				if (jQuery.trim($(this).val()) === '') {
					labelText = $(this).prev('label').text();
					$(this).addClass('fielderror');
					$('#contactForm span').html('<strong>*Please fill out all fields.</strong>');
					hasError = true;
				} else if ($(this).hasClass('email')) {
					var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
					if (!emailReg.test(jQuery.trim($(this).val()))) {
						labelText = $(this).prev('label').text();
						$(this).addClass('fielderror');
						$('#contactForm span').html('<strong>Is incorrect your email address</strong>');
						hasError = true;
					}
				}
			});
			if (!hasError) {
				$('#contactForm').slideDown('normal', function() {
					$("#contactForm #sendMessage").addClass('load-color');
					$("#contactForm #sendMessage").attr("disabled", "disabled").addClass("btn-success").val('Sending message. Please wait...');
				});
				var formInput = $(this).serialize();
				$.post($(this).attr('action'), formInput, function(data) {
					$('#contactForm').slideUp("normal", function() {
						$(this).before('<div class="alert alert-success"><p>Thanks!</strong> Your email was successfully sent. We check Our email all the time.</p></div>');
					});
				});
			}
			return false;
		});
	}
	//if ($("#contactForm-widget")[0]) {
	$('body').on('click', '#sendMessage', function() {

		$('#topcontact').find('.error').remove();
		$('#topcontact').find('.requiredField').removeClass('fielderror');
		$('#topcontact').find('.requiredField').addClass('fieldtrue');
		//$('#contactForm-widget span strong').remove();
		var hasError = false;
		var labelText = '';
		$('#topcontact').find('.requiredField').each(function() {
			if (jQuery.trim($(this).val()) === '') {
				labelText = $(this).prev('label').text();
				$(this).addClass('fielderror');
				//$('#topcontact').find('.error-message').html('<strong>*Please fill out all fields.</strong>');
				layer.alert('*Please fill out all fields.', {
					title: "message",
					btn: ['confirm']
				});
				hasError = true;
				return false;

			} else if ($(this).hasClass('email')) {
				var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
				if (!emailReg.test(jQuery.trim($(this).val()))) {
					labelText = $(this).prev('label').text();
					$(this).addClass('fielderror');
					layer.alert('Is incorrect your email address', {
						title: "message",
						btn: ['confirm']
					});
					hasError = true;
				}
			}
		});
		if (!hasError) {
			$('#topcontact').slideDown('normal', function() {
				$("#sendMessage").addClass('load-color');
				$("#sendMessage").attr("disabled", "disabled").val('Sending message. Please wait...');
				$('#topcontact').find('.error-message').html('<i class="fa fa-spinner fa-spin"></i>');
			});
			layer.load(1);
			var formInput = $('#topcontact').serialize();

			$.ajax({
				url: '/contact_us/',
				data: formInput,
				type: 'post',
				dataType: 'json',
				success: function(data) {
					layer.closeAll('loading');
					$("#sendMessage").val('Send');
					$("#sendMessage").removeAttr("disabled");
					if (data.status) {

						$('.extrabox').slideUp("normal", function() {
							$('#topcontact').find('.error-message').html('');									
							$("#topmessage").val('');
							layer.msg('Thanks! We have receive your message. We replay soon.', {
								icon: 1
							});
						});
					} else {
						

						$('#homeask-img').attr('src', '/captchaimg/?type=homeask-img&rand=' + Math.random());
						layer.alert(data.msg, {
							title: "message",
							btn: ['confirm']
						});
					}

				}
			});
		}
		return false;
	});
	//}
	$('body').on('click', '.logout', function() { //ajax out
		$.ajax({
			url: "/ajaxlogout/",
			dataType: 'json',
			type: 'get',
			success: function(data) {
				if (data.status) {
					if (data.script) { //if there is script need to load
						var index = layer.load(1);
						try {
							loadScripts(data.script, function() { //action after script load

								window.location.href = data.page;


							});
						} catch (err) {

						}
					} else { //if no script need load

						window.location.href = data.page;

					}
				} else {
					layer.alert('logout out error, please delet cookies handly');
				}
			}
		});
	});



	$('body').on('click', '.homeadd', function() {
		var pid = $(this).attr('dataid');
		var self = $(this);
		$.ajax({
			url: '/index.php?main_page=ajaxaddcart',
			data: {
				'doact': "additem",
				'products_id': pid

			},
			dataType: "json",
			type: "POST",
			cache: false,
			success: function(data) {
				if (data.status) {

					$('.totalvalues').html(data.total);
					self.find('i').addClass('fa-check');
					self.find('i').removeClass('fa-cart-plus');
					self.find('span').html('Add success!');
					var nh = self.parent().find('a.needhide');
					if (!nh.length) {
						self.parent().append('<a class="addToCart tooltip-1 needhide"  title="" href="/?action=buy_now&products_id=' + pid + '" data-original-title="Check out" rel="nofollow"><span>Check out</span></a>');
					}
				}
			}
		});
	});

	$('body').on('click', '.addcart', function() {
		var pid = $(this).attr('id');
		$.ajax({
			url: '/index.php?main_page=ajaxaddcart',
			data: {
				'doact': "additem",
				'products_id': pid

			},
			dataType: "json",
			type: "POST",
			cache: false,
			success: function(data) {
				if (data.status) {

					$('.totalvalues').html(data.total);
					$('#' + pid + '_icon').addClass("fa-check");
					$('#' + pid + '_icon').removeClass("fa-cart-plus");
				}
			}
		});
	});

	$('body').on('click', '.sp-plus', function() {
		var oldVal = $('.quantity-input').val();
		var newVal = (parseInt($('.quantity-input').val(), 10) + 1);
		$('.quantity-input').val(newVal);
	});
	$('body').on('click', '.sp-minus', function() {
		var oldVal = $('.quantity-input').val();
		var newVal = 0;
		newVal = (parseInt($('.quantity-input').val(), 10) - 1);
		if (oldVal > 1) {
			newVal = parseFloat(oldVal) - 1;
		} else {
			newVal = 1;
		}
		$('.quantity-input').val(newVal);
	});

	$(".product_image a").tooltip({
		placement: 'top'
	});
	$(".arrow-down a").tooltip({
		placement: 'left'
	});
	$("header h1 i.fa").tooltip({
		placement: 'top'
	});
	$(".product-details-review .smallProductImage a").tooltip({
		placement: 'top'
	});
	$(".product-details-review .product-review-default h4 a").tooltip({
		placement: 'top'
	});
	$(".product-details-review .product-review-default p a").tooltip({
		placement: 'top'
	});
	$(".social_bookmarks li a").tooltip({
		placement: 'top'
	});
	$("#select-filter_id").select2({
		width: "element"
	});
	$("#select-alpha_filter_id").select2({
		width: "element"
	});
	$("#select-zone_country_id").select2({
		width: "element"
	});
	$("#disp-order-sorter").select2({
		width: "element"
	});
	$("#select-categories_id").select2({
		width: "element"
	});
	$("#select-manufacturers_id").select2({
		width: "element"
	});

	$('body').on('click', '.addwticon', function() {
		var id = $(this).attr('dataid');
		var self = $(this);
		$.ajax({
			type: 'post',
			data: {
				'doact': 'addwishlist',
				'products_id': id
			},
			url: '/ajaxaddwish/',
			dataType: 'json',
			success: function(data) {
				if (data.status) {
					self.find('i').addClass('fa-check');
					self.find('i').removeClass('fa-heart');

				} else {
					if (data.code == 2) {
						var url = $('#loginbtn').attr('datesrc');
						loginframe(url);
					} else {
						layer.msg(data.msg);
					}

				}
			}


		});
	});

	$('body').on('click', '.paddwish', function() {
		var id = $(this).attr('dataid');
		var self = $(this);
		$.ajax({
			type: 'post',
			data: {
				'doact': 'addwishlist',
				'products_id': id
			},
			url: '/ajaxaddwish/',
			dataType: 'json',
			success: function(data) {
				if (data.statue) {
					self.html('<strong>Add Success</strong>');
					self.prev().addClass('fa-check');
					self.prev().removeClass('fa-heart');

				} else {
					$('#loginregist').modal('show');
				}
			}


		});

	});
	$('a.moduleBox').click(function() { // show selected module(s)
		// variables
		var popID = $(this).attr('rel');
		var popNAV = $(this).attr('class');
		// clear out menu styles and apply active
		$('a.moduleBox').css('background', '');
		$(this).css('background', '');
		// hide all wrappers and display the one selected
		$('.centerBoxWrapper').hide();
		// check if all or single selection
		if (popID != 'viewAll') {
			$('#' + popID).show();
		} else {
			$('.centerBoxWrapper').show();
		}
	});

	$('a.navOne').click(function() {
		$('a.navOne').addClass('active');
		$('a.navTwo').removeClass('active');
		$('a.navThree').removeClass('active');
	});

	$('a.navTwo').click(function() {
		$('a.navOne').removeClass('active');
		$('a.navTwo').addClass('active');
		$('a.navThree').removeClass('active');
	});

	$('a.navThree').click(function() {
		$('a.navOne').removeClass('active');
		$('a.navTwo').removeClass('active');
		$('a.navThree').addClass('active');
	});
	//change country shipping fee



	//htmlobj=$.ajax({url:"/ajaxship/?zone_country_id="+countryvalue+"&product_price_ship="+price+",async:false});
	//$("#shiptable tbody").html(htmlobj.responseText);



	$("#addwish").click(function() {
		$('#themodal').modal('show');
	});

	$('body').on('click', '.reviewlink', function() {
		var pid = $(this).attr('pid');
		var page = parseInt($(this).attr('page'));
		var theli = this;
		$.ajax({
			url: '/index.php?main_page=ajaxreview',
			data: {
				'productid': pid,
				'page': page
			},
			dataType: "json",
			type: "POST",
			cache: false,
			success: function(data) {
				if (data.status) {
					var html = "";
					$.each(data.data, function(i, obj) {
						html += '<div itemtype="http://schema.org/Review" itemscope="" itemprop="review"><li><meta content="' + obj.date_added + '" itemprop="datePublished">Date Added: ' + obj.date_added + '&nbsp;by <span itemprop="author">' + obj.customers_name + '</span></li><li><img width="106" height="21" title="' + obj.reviews_rating + ' of 5 Stars! " alt="' + obj.reviews_rating + ' of 5 Stars!" src="includes/templates/edify/images/stars_' + obj.reviews_rating + '.gif">' + obj.reviews_rating + ' of 5 Stars!</li><li style="height:auto;"><span itemprop="reviewBody">' + obj.reviews_text + '</span></li><li style="line-height: 10px;border-bottom:1px dotted #540E68;">&nbsp;</li></div>';
					});
					$('#review_list').html(html);
					$('.pagination').find('li').each(function() {
						if ($(this).hasClass("active")) {
							$(this).removeClass("active");
						}
						if ($(this).find('a').attr('page') == page && !$(this).find('a').hasClass("jiantou")) {
							$(this).addClass("active");
						}
					});
					//$(theli).addClass('active');
				}
			}
		});


	});
	
	jQuery.loadScript = function(url, callback) {
		jQuery.ajax({
			url: url,
			dataType: 'script',
			success: callback,
			async: true
		});
	};
	this.reloadcaptcha = function() {
		var zendid = $('#zendid').val();
		$('#captcha-img').attr('src', '/captcha_img.php?zenid=' + zendid + '&rand=' + Math.random());
	};



});

window.loginframe=function(url) {
	var twidth = parseInt(document.body.clientWidth * 0.80);
	var loginiframe = layer.open({
		title: 'Login OR Register',
		type: 2,
		area: [twidth + 'px', '530px'],
		content: url,
		closeBtn:1,
		success: function(layero, index) {


			window.addEventListener("message", receiveMessage, false);


		},
		end: function() {
			window.removeEventListener("message", receiveMessage, false);
		}
	});

};


function receiveMessage(event) {

	var origin = event.origin || event.originalEvent.origin;
	var domins = document.domain;
	console.log(domins);
	console.log(event.data);
	console.log(event.origin);
	// if (origin !== "https://www." + domins && origin !== "http://www." + domins&&(origin.indexOf(domins)!= -1)) {
	// 	return;
	// }
	
	if (event.data == "login success"||event.data == "register success") {

		loginaction();
	}
}



function loadScripts(array, callback) {
	var loader = function(src, handler) {
		var script = document.createElement("script");
		script.src = src;
		script.onload = script.onreadystatechange = function() {
			script.onreadystatechange = script.onload = null;
			handler();
		};
		var head = document.getElementsByTagName("head")[0];

		(head || document.body).appendChild(script);
	};
	(function() {
		if (array.length != 0) {
			loader(array.shift(), arguments.callee);
		} else {
			callback && callback();
		}
	})();
}


function addScript(src) {
	var s = document.createElement('script');
	s.setAttribute('src', src);
	document.body.appendChild(s);
}

function vg(star) {
	return document.getElementById(star).value;
}

function validemail(emailval) {
	var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	return filter.test(emailval);
}

function reloadcaptcha() {
	var zendid = $('#zendid').val();

	$('#captcha-img').attr('src', '/captcha_img.php?zenid=' + zendid + '&rand=' + Math.random());
}

function getmdpass(pass) {
	var $ = jQuery.noConflict();
	var seckey = $('#seckey').val();
	var salt = CryptoJS.lib.WordArray.random(128 / 8);
	var key256Bits500Iterations = CryptoJS.PBKDF2("Secret Passphrase", salt, {
		keySize: 256 / 32,
		iterations: 500
	});
	var iv = CryptoJS.enc.Hex.parse(seckey);
	return CryptoJS.AES.encrypt(pass, key256Bits500Iterations, {
		iv: iv
	});
}

function closemodal(typeid) {
	var $ = jQuery.noConflict();
	//$('#'+).modal('hide');
	$(typeid).modal('hide');
}


function loginaction() {
	var turl = window.location.href;

	if (turl.indexOf("main_page=shopping_cart") >= 0) {

		self.location = '/index.php?main_page=checkout_shipping';
	} else if (turl.indexOf("logoff") >= 0) {
		self.location = '/';
	} else {

		location.reload();
	}
}
