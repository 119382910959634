import '../css/font-awesome.css';
import '../css/style_imagehover.css';
import '../css/style_wishlist_classic.css';
import '../css/stylesheet.css';
import '../css/stylesheet_animate.css';
//import '../css/stylesheet_bootstrap-theme.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
//import '../css/stylesheet_bootstrap.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../css/stylesheet_categories_menu.css';
import '../css/stylesheet_devicecss.css';
import '../css/stylesheet_layer.css';
import '../css/stylesheet_menumaker.css';
import '../css/stylesheet_referrers.css';
import '../css/stylesheet_select2.css';
import '../css/stylesheet_slim-081711.css';
import '../css/stylesheet_templatecss.css';
import '../css/stylesheet_zen_lightbox.css';

// require("script-loader!jquery");

// window.jQuery = $;
// window.$ = $;

//require("bootstrap/dist/js/bootstrap.min.js");
require("bootstrap/dist/js/bootstrap.min.js");
//require('../jscript/jscript_cloud-zoom.1.0.3.js');
require('../jscript/common.js');
require('../jscript/jscript_css_browser_selector.js');
require('../jscript/jscript_imagehover.js');
require('../jscript/jscript_jquery.colorbox.js');

require('../jscript/jscript_jquery.nicescroll.min.js');
require('../jscript/jscript_layer.js');
require('../jscript/jscript_menumaker.js');
require('../jscript/jscript_owl.carousel.js');
require('../jscript/jscript_select2.js');
require('../jscript/jscript_tabcontent.js');
require('../jscript/jscript_imagehover.js');
//require('../jscript/jscript_wow.min.js');
const WOW = require('wowjs/dist/wow.js');
require('../jscript/jscript_z_edify_homepage_2.js');
require('../jscript/jscript_zpopup.js');
window.wow = new WOW.WOW({
    live: false,
    boxClass: 'wow', // animated element css class (default is wow)
	animateClass: 'animated', // animation css class (default is animated)
	offset: 0, // distance to the element when triggering the animation (default is 0)
	mobile: false // trigger animations on mobile devices (true is default)
});
window.wow.init();
